<template>
  <div class="mx-auto justify-content-center d-flex flex-column">
    <PageHeader :title="bodyPartName" class="text-center mt-3" />
    <div v-if="pageReady" class="container-fluid my-3">
      <div>
        <!-- Lumbar Spine -->
        <h3 class="text-center my-5 lined-title">
          <span>Muscles</span>
        </h3>
        <div class="py-3 d-flex">
          <div class="col">
            <p class="d-inline">
              Normal muscle tone, without spasm or tenderness?
            </p>
          </div>

          <div class="col">
            <BaseRadioGroup
              v-model="isMuscleNormal"
              :options="defaultOptions"
              :label="`Normal_muscle_tone`"
            />
          </div>
        </div>

        <div v-if="!isMuscleNormal">
          <div
            v-for="m in muscle"
            :key="m"
            class="row justify-content-between py-3"
          >
            <h3 class="text-center my-5 lined-title text-capitalize">
              <span>{{ m.name }}</span>
            </h3>
            <div class="col">
              <p class="flex-grow-1">Signs of tenderness ?</p>
              <form>
                <BaseRadioGroup
                  v-model="m.tenderness"
                  :options="LRBRadios"
                  :label="`${m.name} Signs of tenderness`"
                />
              </form>
            </div>

            <div class="col">
              <p class="flex-grow-1">Signs of spasm ?</p>
              <BaseRadioGroup
                v-model="m.spasm"
                :options="LRBRadios"
                :label="`${m.name} Signs of spasm`"
              />
            </div>
          </div>
        </div>

        <h3 class="text-center my-5 lined-title">
          <span>Movement</span>
        </h3>

        <div class="py-3 d-flex">
          <div class="col">
            <p class="d-inline">Normal range of movement?</p>
          </div>

          <div class="col">
            <BaseRadioGroup
              v-model="isNormal"
              :options="defaultOptions"
              :label="`range of movement`"
            />
          </div>
        </div>
        <div v-if="parts.length > 0" class="mx-auto py-3">
          <DefaultAssessmentTable
            :table-head="tableHeader"
            :table-rows="tableData"
          />
        </div>

        <h3 class="text-center my-5 lined-title">
          <span>Neurological</span>
        </h3>
        <div class="d-flex flex-column">
          <div class="col-12 py-3">
            <p class="d-inline me-5">Normal sensation, power and reflexes?</p>

            <BaseRadioGroup
              v-model="isNormalNeurology"
              :options="defaultOptions"
              :label="`Normal sensation`"
            />
          </div>
          <div v-if="!isNormalNeurology">
            <!-- painlevel -->

            <div class="col-12 py-4">
              <p class="d-inline me-5">Pain sensation (Pin Prick Test):</p>

              <BaseRadioGroup
                v-model="neuro.sensation.pain_level"
                :options="painLevelRadios"
                :label="`Pain sensation`"
              />

              <div
                v-if="neuro.sensation.pain_level !== 'normal'"
                class="row py-3"
              >
                <p class="d-inline">Additional information:</p>

                <textarea
                  v-model="neuro.sensation.abnormal_pain_additional_info"
                  class="form-control w-75 mx-2"
                  rows="2"
                />
              </div>
            </div>

            <!-- powertest -->

            <div class="col-12 py-4">
              <p class="d-inline me-5">Power test?</p>

              <BaseRadioGroup
                v-model="neuro.muscle_weakness.power_level"
                :options="powerLevelRadios"
                :label="`Power test`"
              />

              <div
                v-if="neuro.muscle_weakness.power_level == 'reduced'"
                class="row py-3"
              >
                <div class="col-5">
                  <label class="form-label">
                    Reduced strength:
                    <strong>
                      {{ neuro.muscle_weakness.strength_reduced_level }}
                    </strong>
                  </label>
                  <input
                    v-model="neuro.muscle_weakness.strength_reduced_level"
                    type="range"
                    class="form-range"
                    min="0"
                    max="5"
                  />
                </div>
                <div class="col-7">
                  <p class="d-inline flex-grow-1">Additional information:</p>

                  <textarea
                    v-model="neuro.muscle_weakness.power_reduced_info"
                    class="form-control"
                    rows="2"
                  />
                </div>
              </div>
            </div>

            <!-- reflexestest -->

            <div class="col-12 py-4">
              <p class="d-inline me-5">Reflexes test?</p>

              <BaseRadioGroup
                v-model="reflexes"
                :options="reflexesTestRadios"
                :label="`Reflexes test`"
              />

              <div v-if="reflexes === 'abnormal'" class="row py-4">
                <div class="col">
                  <h3>Right</h3>
                  <hr class="w-50" />

                  <div class="pb-4">
                    <label>Bicep Reflexes:</label>
                    <BaseRadioGroup
                      v-model="neuro.reflexes.bicep.right"
                      :inline="false"
                      :options="reflexesRadios"
                      :label="`reflexes_bicep_right`"
                    />
                  </div>

                  <div class="pb-4">
                    <label>Brachioradialis Reflexes:</label>
                    <BaseRadioGroup
                      v-model="neuro.reflexes.brachioradialis.right"
                      :inline="false"
                      :options="reflexesRadios"
                      :label="`reflexes_brachioradialis_right`"
                    />
                  </div>

                  <div class="pb-4">
                    <label>Tricep Reflexes:</label>
                    <BaseRadioGroup
                      v-model="neuro.reflexes.tricep.right"
                      :inline="false"
                      :options="reflexesRadios"
                      :label="`reflexes_tricep_right`"
                    />
                  </div>
                </div>

                <div class="col">
                  <h3>Left</h3>
                  <hr class="w-50" />

                  <div class="pb-4">
                    <label>Bicep Reflexes:</label>
                    <BaseRadioGroup
                      v-model="neuro.reflexes.bicep.left"
                      :inline="false"
                      :options="reflexesRadios"
                      :label="`reflexes_bicep_left`"
                    />
                  </div>

                  <div class="pb-4">
                    <label>Brachioradialis Reflexes:</label>
                    <BaseRadioGroup
                      v-model="neuro.reflexes.brachioradialis.left"
                      :inline="false"
                      :options="reflexesRadios"
                      :label="`reflexes_brachioradialis_left`"
                    />
                  </div>

                  <div class="pb-4">
                    <label>Tricep Reflexes:</label>
                    <BaseRadioGroup
                      v-model="neuro.reflexes.tricep.left"
                      :inline="false"
                      :options="reflexesRadios"
                      :label="`reflexes_tricep_left`"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- roostest -->

            <div class="col-12 py-4">
              <p class="d-inline me-5">Roos test?</p>

              <BaseRadioGroup
                v-model="neuro.roos_test.value"
                :options="roosTestOptions"
                :label="`Roos test`"
              />

              <div v-if="neuro.roos_test.value == 'positive'" class="row py-3">
                <div class="col-5">
                  <label class="form-label">
                    Additional Information:
                    <strong>
                      {{ neuro.roos_test.comments }}
                    </strong>
                  </label>

                  <textarea
                    v-model="neuro.roos_test.comments"
                    :placeholder="
                      neuro.roos_test.comments
                        ? neuro.roos_test.comments
                        : 'Please provide reasons...'
                    "
                    class="form-control"
                    rows="3"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- abnormalclinicalfinding -->

          <div class="py-4">
            <p class="me-5 d-inline mb-3">
              Are the clinical findings consistent with the diagnosis?
            </p>

            <BaseRadioGroup
              v-model="findings.consistent_clinical_findings"
              :options="defaultOptions"
              :label="`noAbnormalClinicalFindings`"
            />

            <textarea
              v-if="!findings.consistent_clinical_findings"
              v-model="findings.consistent_clinical_findings_comment"
              :placeholder="
                noAbnormalClinicalFindings
                  ? `Patient's ${selectedPart.toLowerCase()} has no abnormal clinical findings. `
                  : 'Please provide reasons...'
              "
              class="form-control w-75"
              rows="3"
            />
          </div>
        </div>

        <h3 class="text-center my-5 lined-title">
          <span>Muscle Wastings</span>
        </h3>
        <div class="py-4">
          <p class="d-inline me-5">Atrophy of the injured side?</p>

          <BaseRadioGroup
            v-model="muscle_wasting.atrophy"
            :options="defaultOptions"
            :label="`neuro.muscle_wasting_atrophy`"
          />
        </div>

        <div class="d-flex row justify-content-between py-4">
          <div class="col-6">
            <p>Arm circumferences (cm)</p>
            <hr />
            <div class="row">
              <div class="col-3">
                <BaseInput
                  v-model="muscle_wasting.arm['right']"
                  :label="`Right:`"
                  :placeholder="`...cm`"
                  :type="`number`"
                />
              </div>
              <div class="col-3">
                <BaseInput
                  v-model="muscle_wasting.arm['left']"
                  :label="`Left:`"
                  :placeholder="`...cm`"
                  :type="`number`"
                />
              </div>
              <div class="col-6">
                <BaseInput
                  v-model="muscle_wasting.arm['distance_above_elbow']"
                  :label="`Distance above elbow:`"
                  :placeholder="`...cm`"
                  :type="`number`"
                />
              </div>
            </div>
          </div>

          <div class="col-6">
            <p>Forearm circumferences (cm)</p>
            <hr />
            <div class="row">
              <div class="col-3">
                <BaseInput
                  v-model="muscle_wasting.forearm['right']"
                  :placeholder="`...cm`"
                  :label="`Right:`"
                  :type="`number`"
                />
              </div>
              <div class="col-3">
                <BaseInput
                  v-model="muscle_wasting.forearm['left']"
                  :label="`Left:`"
                  :placeholder="`...cm`"
                  :type="`number`"
                />
              </div>
              <div class="col-6">
                <BaseInput
                  v-model="muscle_wasting.forearm['distance_above_elbow']"
                  :label="`Distance above elbow:`"
                  :placeholder="`...cm`"
                  :type="`number`"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- radiologyfindings -->
        <h3 class="text-center my-5 lined-title">
          <span>Radiology</span>
        </h3>

        <div class="col">
          <p class="flex-grow-1">How were the radiology findings?</p>
          <BaseRadioGroup
            v-model="isAbnormalRadiology"
            :options="abnormalRadiology"
            :label="`abnormal_radiology`"
          />
        </div>

        <div v-if="isAbnormalRadiology" class="col my-5">
          <p class="flex-grow-1">
            Please select the options below and provide details:
          </p>

          <div
            v-for="option in radiologyFindings"
            :key="option.name"
            class="form-check-inline"
          >
            <input
              :id="`${option.name}`"
              v-model="radiology_selected_findings"
              :value="option"
              class="form-check-input me-2"
              type="checkbox"
              :name="`${option.name}`"
            />
            <label class="form-check-label" :for="`${option.name}`">
              {{ option.name }}
            </label>
          </div>

          <div class="pt-5">
            <div
              v-if="radiologyObjNameCheck('Disc protrusion')"
              class="p-4 mb-5 mx-1 card"
            >
              <h3 class="text-decoration-underline mb-5">Disc Protrusion</h3>
              <div class="row">
                <div class="col-6">
                  <p>Scan selected:</p>
                  <BaseCheckboxGroup
                    v-model="radiology.disc_protrusion.scan"
                    :label="`disc_protrusion_imaging`"
                    :options="scans"
                  />
                </div>
                <div class="col-6">
                  <p>Please provide more details:</p>
                  <textarea
                    v-model="radiology.disc_protrusion.detail"
                    class="form-control"
                    rows="2"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="radiologyObjNameCheck('Disc extrusion')"
              class="p-4 mb-5 mx-1 card"
            >
              <h3 class="text-decoration-underline mb-5">Disc Extrusion</h3>

              <div class="row">
                <div class="col-6">
                  <p>Scan selected:</p>

                  <BaseCheckboxGroup
                    v-model="radiology.disc_extrusion.scan"
                    :label="`disc_extrusion_imaging`"
                    :options="scans"
                  />
                </div>

                <div class="col-6">
                  <label>Please provide more details:</label>
                  <textarea
                    v-model="radiology.disc_extrusion.detail"
                    class="form-control"
                    rows="2"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="radiologyObjNameCheck('Nerve root compression')"
              class="p-4 mb-5 mx-1 card"
            >
              <div class="mb-5">
                <h3 class="text-decoration-underline d-inline">
                  Nerve Root Compression
                </h3>
                <button
                  class="btn btn-sm btn-dark float-end rounded-pill px-3"
                  @click="addInput('nerve')"
                >
                  + Input
                </button>
              </div>

              <div
                v-for="(input, idx) in radiology.nerve_root_compression"
                :key="idx"
                class="card mt-3"
              >
                <div
                  class="card-header text-center d-flex justify-content-between align-items-center"
                >
                  <span>Input {{ idx + 1 }}</span>
                  <button
                    type="button"
                    class="btn btn-danger text-white btn-sm px-2 py-1"
                    @click="delInput('nerve_root_compression', idx)"
                  >
                    x
                  </button>
                </div>

                <div class="p-4 justify-content-center row">
                  <div class="col-6">
                    <p>Scan selected:</p>
                    <div>
                      <BaseCheckboxGroup
                        v-model="input.scan"
                        :inline="false"
                        :label="`nerve_root_compression`"
                        :options="scans"
                      />
                    </div>
                  </div>

                  <div class="col-6">
                    <p>Segment:</p>

                    <div
                      v-for="option in segments"
                      :id="`${'segment_' + option}`"
                      :key="option"
                      class="row my-2"
                    >
                      <span class="col-1 p-0 my-auto text-capitalize">
                        {{ option.name }}
                      </span>
                      <span class="col-1 my-auto border-bottom"></span>
                      <div class="col-10">
                        <BaseRadioGroup
                          v-model="input.segments[`${option.name}`]"
                          :options="LRBRadios"
                          :label="
                            `nerve_root_compression_` +
                            `${option.name}` +
                            `${idx + 1}`
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="radiologyObjNameCheck('Fractures')"
              class="p-4 mb-5 mx-1 card"
            >
              <div class="mb-5">
                <h3 class="text-decoration-underline d-inline">Fracture</h3>
              </div>

              <div class="card mt-3">
                <div class="p-4 justify-content-center row">
                  <div class="col-6 px-3">
                    <p>Scan selected:</p>
                    <div>
                      <BaseCheckboxGroup
                        v-model="radiology.spinal_fractures.scan"
                        :label="`spinal_fractures`"
                        :options="scans"
                      />
                    </div>
                  </div>

                  <div class="col-6 px-2">
                    <p>Segment:</p>

                    <div
                      v-for="option in segmentsFracture"
                      :key="option.name"
                      class="form-check-inline text-capitalize"
                    >
                      <input
                        :id="`${option.name + 'fractures_segments'}`"
                        v-model="radiology.spinal_fractures.segments"
                        :value="option"
                        class="form-check-input me-2"
                        type="checkbox"
                        :name="`${option.name}`"
                      />
                      <label class="form-check-label" :for="`${option.name}`">
                        {{ option.name }}
                      </label>
                    </div>
                  </div>
                </div>

                <div class="p-4">
                  <p class="mt-3 text-center">
                    Vertebral Height Loss (%) for selected segments:
                  </p>

                  <div class="row w-100">
                    <div
                      v-for="item in radiology.spinal_fractures.segments"
                      :key="item.name"
                      class="col-sm-2"
                    >
                      <div class="input-group">
                        <span class="input-group-text text-capitalize">
                          {{ item.name }}
                        </span>
                        <input
                          v-model="item.value"
                          class="form-control"
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-4">
                <p class="mb-2 me-5">
                  Posterior element (lamina, pars or pedicle) fracture?
                </p>

                <BaseRadioGroup
                  v-model="
                    radiology.spinal_fractures[`fracture_posterior_element`]
                  "
                  :options="fracturePosteriorOptions"
                  :label="`fracture_posterior_elements`"
                />
              </div>

              <div class="py-4">
                <p class="mb-2 me-5">
                  Alteration of motion segment integrity eg fusion/arthrodesis?
                </p>

                <BaseRadioGroup
                  v-model="radiology.spinal_fractures[`fracture_alteration`]"
                  :options="defaultOptions"
                  :label="`fracture_alteration`"
                />
              </div>
            </div>

            <div
              v-if="radiologyObjNameCheck('Others')"
              class="p-4 mb-5 mx-1 card"
            >
              <h3 class="text-decoration-underline mb-3">Others:</h3>

              <div class="col">
                <label>
                  Please add the specific abnormal radiology findings (if
                  needed):
                </label>

                <textarea
                  v-model="radiology.other_findings.detail"
                  class="form-control"
                  rows="3"
                />
              </div>
            </div>
          </div>

          <div class="py-4">
            <p class="mb-2 me-5">
              Are the radiology finding(s) consistent with the clinical finding?
            </p>
            <BaseRadioGroup
              v-model="findings.radiology_findings_consistent"
              :options="defaultOptions"
              :label="`radiology_findings_consistent`"
            />
          </div>
        </div>

        <h3 class="text-center my-5 lined-title">
          <span>{{ selectedPart }} Diagnosis</span>
        </h3>

        <div class="py-4">
          <p>Specific diagnosis:</p>

          <div class="me-4 mb-3">
            <BaseCheckboxGroup
              v-model="diagnosis.specific"
              :options="specificDiagnosis"
              :label="`diagnosis.specific`"
            />
          </div>

          <div v-if="diagnosis.specific.includes('Other')" class="mt-4">
            <label class="form-label">Other:</label>
            <textarea
              v-model="diagnosis.other_specific_diagnosis"
              placeholder="Additional Information"
              class="form-control w-75"
              rows="2"
            />
          </div>
        </div>

        <div class="py-5 d-flex flex-column">
          <div>
            <p class="d-inline flex-grow-1 me-5">Surgical decompression?</p>

            <BaseRadioGroup
              v-model="diagnosis.surgical_decompression"
              :options="defaultOptions"
              :label="`Surgical decompression`"
            />
          </div>

          <div v-if="diagnosis.surgical_decompression" class="row my-4">
            <div class="col">
              <label>Number of surgeries:</label>
              <select v-model="diagnosis.surgical.num" class="form-select w-50">
                <option v-for="option in surgicalNum" :key="option">
                  {{ option }}
                </option>
              </select>
            </div>

            <div class="col">
              <p class="flex-grow-1 mb-2">Same level?</p>

              <BaseRadioGroup
                v-model="diagnosis.surgical.same_level"
                :options="defaultOptions"
                :label="`same level of surgical`"
              />
            </div>
          </div>
        </div>

        <div class="py-4">
          <p class="d-inline flex-grow-1 me-5">
            Is the reported ADLs loss consistent with the clinical assessment?
          </p>

          <BaseRadioGroup
            v-model="diagnosis.adl_consistent"
            :options="defaultOptions"
            :label="`reported_adl_consistent`"
          />

          <textarea
            v-if="!diagnosis.adl_consistent"
            v-model="diagnosis.adl_inconsistent_reasons"
            class="form-control my-3"
            placeholder="please enter additional detail..."
            rows="2"
          />
        </div>
      </div>

      <div class="d-flex justify-content-between py-3">
        <BaseBtn
          :class="['px-3']"
          :text-color="`danger`"
          :color="`outline-danger`"
          :data-bs-toggle="`modal`"
          :data-bs-target="`#confirmationModal`"
          @click="onSave"
        >
          Delete
        </BaseBtn>

        <BaseBtn :class="`px-3`" @click="onSave">Save</BaseBtn>
      </div>
    </div>

    <!-- cancel modal -->
    <div
      id="confirmationModal"
      class="modal fade confirmation-box"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title px-2">Confirmation Box</h5>

            <BaseBtn
              :class="` mx-auto `"
              :color="`close`"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></BaseBtn>
          </div>
          <div class="modal-body py-4">
            <p>
              Are you sure to cancel and remove this
              {{ selectedPart }} selection?
            </p>
          </div>
          <div class="mb-4 d-flex justify-content-between">
            <BaseBtn
              :class="` mx-auto `"
              :color="`secondary`"
              :data-bs-dismiss="`modal`"
            >
              Bring me back
            </BaseBtn>

            <BaseBtn :class="` mx-auto `" :color="`danger`">
              <strong>Save</strong>
            </BaseBtn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultAssessmentTable from '@/components/Assessment/DefaultAssessmentTable.vue'
import PageHeader from '@/components/BodyPart/PageHeader.vue'
import BaseRadioGroup from '@/components/Base/BaseRadioGroup.vue'
import BaseBtn from '@/components/Base/BaseButton.vue'
import BaseInput from '@/components/Base/BaseInput.vue'
import BaseCheckboxGroup from '@/components/Base/BaseCheckboxGroup.vue'

export default {
  name: 'CervicalSpine',
  components: {
    PageHeader,
    DefaultAssessmentTable,
    BaseRadioGroup,
    BaseBtn,
    BaseInput,
    BaseCheckboxGroup,
  },

  props: {
    assessmentId: { type: Number, default: null },
    selectedPart: { type: String, required: true },
    existingTableData: { type: Object, default: null },
    apiPostObj: { type: [Object, Array], default: null },
  },

  emits: ['update:readyNext'],

  data() {
    return {
      pageReady: false,
      bodyPartName: null,

      muscle: [
        {
          name: 'paracervical muscles',
          tenderness: 'none',
          spasm: 'none',
        },
        { name: 'trapezius muscles', tenderness: 'none', spasm: 'none' },
      ],
      neuro: {
        sensation: {
          pain_level: 'normal',
          abnormal_pain_additional_info: '',
        },
        muscle_weakness: {
          power_level: 'normal',
          strength_reduced_level: 0,
          power_reduced_info: null,
        },
        reflexes: {
          bicep: {
            right: 'normal',
            left: 'normal',
          },
          brachioradialis: {
            right: 'normal',
            left: 'normal',
          },

          tricep: {
            right: 'normal',
            left: 'normal',
          },
        },
        roos_test: {
          value: 'not applicable',
          comments: '',
        },
      },
      findings: {
        abnormal_radiological_findings: null,
        radiology_findings_consistent: true,
        consistent_clinical_findings: true,
        consistent_clinical_findings_comment: null,
      },
      muscle_wasting: { atrophy: false, arm: {}, forearm: {} },
      reflexes: 'normal',
      radiology_selected_findings: [],
      radiology: {
        disc_protrusion: { scan: [], detail: '' },
        disc_extrusion: { scan: [], detail: '' },
        nerve_root_compression: [
          {
            scan: [],
            segments: {
              c1: 'none',
              c2: 'none',
              c3: 'none',
              c4: 'none',
              c5: 'none',
              c6: 'none',
              c7: 'none',
              c8: 'none',
            },
          },
        ],
        spinal_fractures: {
          segments: [],
          scan: [],
        },
        other_findings: { detail: '' },
      },
      diagnosis: {
        specific: [],
        other_specific_diagnosis: '',
        surgical: {
          num: 0,
          same_level: null,
        },
        surgical_decompression: false,
        adl_consistent: true,
        adl_inconsistent_reasons: '',
      },
      reflexesRadios: ['normal', 'absent', 'reduced', 'increased'],
      radiologyFindings: [
        { name: 'Disc protrusion', value: 'disc_protrusion' },
        { name: 'Disc extrusion', value: 'disc_extrusion' },
        { name: 'Nerve root compression', value: 'nerve_root_compression' },
        { name: 'Fractures', value: 'spinal_fractures' },
        { name: 'Others', value: 'other_findings' },
      ],
      specificDiagnosis: [
        'Musculoligamentous strain of the cervical spine',
        'Cervical spine disc protrusion',
        'Cervical spine disc extrusion',
        'Cervical spine disc protrusion with radiculopathy',
        'Other',
      ],
      scans: ['MRI', 'CT Scan', 'Xray', 'Other'],
      segments: [
        { name: 'c1', value: '' },
        { name: 'c2', value: '' },
        { name: 'c3', value: '' },
        { name: 'c4', value: '' },
        { name: 'c5', value: '' },
        { name: 'c6', value: '' },
        { name: 'c7', value: '' },
        { name: 'c8', value: '' },
      ],
      segmentsFracture: [
        { name: 'c1', value: '' },
        { name: 'c2', value: '' },
        { name: 'c3', value: '' },
        { name: 'c4', value: '' },
        { name: 'c5', value: '' },
        { name: 'c6', value: '' },
        { name: 'c7', value: '' },
      ],
      directions: ['Left', 'Right', 'Bilateral'],
      surgicalNum: [1, 2, 3, 4, 5],
      noAbnormalClinicalFindings: true,

      isNormal: true,
      isMuscleNormal: true,
      isNormalNeurology: true,
      isAbnormalRadiology: false,

      //temporary data
      tableHeader: ['Motion of Cervical Spine', ''],
      parts: [
        { field_name: 'Flexion', degrees: [], degree_id: null, default: 50 },
        { field_name: 'Extension', degrees: [], degree_id: null, default: 60 },
        {
          field_name: 'Left lateral flexion',
          degrees: [],
          degree_id: null,
          default: 45,
        },
        {
          field_name: 'Right lateral flexion',
          degrees: [],
          degree_id: null,
          default: 45,
        },
        {
          field_name: 'Left rotation',
          degrees: [],
          degree_id: null,
          default: 80,
        },
        {
          field_name: 'Right rotation',
          degrees: [],
          degree_id: null,
          default: 80,
        },
      ],
      defaultOptions: [
        {
          value: true,
          label: 'Yes',
        },
        {
          value: false,
          label: 'No',
        },
      ],
      fracturePosteriorOptions: [
        {
          value: 'single',
          label: 'Single',
        },
        {
          value: 'multiple',
          label: 'Multiple',
        },
      ],

      neuroSlumpTestOptions: [
        {
          value: 'positive',
          label: 'Positive',
        },
        {
          value: 'negative',
          label: 'Negative',
        },
      ],
      roosTestOptions: [
        {
          value: 'positive',
          label: 'Positive',
        },
        {
          value: 'negative',
          label: 'Negative',
        },
        {
          value: 'not applicable',
          label: 'Not Applicable',
        },
      ],
      reflexesTestRadios: [
        { label: 'Normal', value: 'normal' },
        { label: 'Abnormal', value: 'abnormal' },
      ],
      powerLevelRadios: [
        { label: 'Normal', value: 'normal' },
        { label: 'Reduced', value: 'reduced' },
      ],
      painLevelRadios: [
        { label: 'Normal', value: 'normal' },
        { label: 'Reduced', value: 'reduced' },
        { label: 'Increased', value: 'increased' },
      ],
      powerLevelOptions: [
        {
          value: 'left',
          label: 'Left',
        },
        {
          value: 'right',
          label: 'Right',
        },
        {
          value: 'none',
          label: 'none',
        },
      ],

      LRBRadios: [
        {
          value: 'left',
          label: 'Left',
        },
        {
          value: 'right',
          label: 'Right',
        },
        {
          value: 'both',
          label: 'Both',
        },
        {
          value: 'none',
          label: 'None',
        },
      ],
      abnormalRadiology: [
        {
          value: false,
          label: 'Normal',
        },
        {
          value: true,
          label: 'Abnormal Radiology Findings',
        },
      ],
    }
  },

  computed: {
    tableData: function () {
      let tableAry = []

      if (this.existingTableData && this.existingTableData.length > 0) {
        tableAry = this.existingTableData
      } else if (this.parts.length > 0) {
        tableAry = this.parts

        if (this.isNormal) {
          tableAry.forEach(
            (x) =>
              (x.degree_id = x.degrees.find((e) => e.degree === x.default).id)
          )
        } else tableAry.forEach((x) => (x['degree_id'] = null))
      }

      return tableAry
    },
  },

  async mounted() {
    // await this.insertDefaults(this.data);

    this.bodyPartName = this.selectedPart

    this.parts.forEach((x) => {
      for (let i = 0; i < 91; i += 5) {
        x.degrees.push({ degree: i, id: i + 1 })
      }
    })

    this.pageReady = true
  },

  // created() {
  //   this.$watch('muscle.paracervical.tenderness', () => {
  //     if (this.muscle.paracervical.tenderness.includes('none')) {
  //       let left = this.muscle.paracervical.tenderness.indexOf('left')

  //       left != -1 ? this.muscle.paracervical.tenderness.splice(left, 1) : ''

  //       let right = this.muscle.paracervical.tenderness.indexOf('right')
  //       right != -1 ? this.muscle.paracervical.tenderness.splice(right, 1) : ''
  //     }
  //   })

  //   this.$watch('muscle.paracervical.spasm', () => {
  //     if (this.muscle.paracervical.spasm.includes('none')) {
  //       let left = this.muscle.paracervical.spasm.indexOf('left')

  //       left != -1 ? this.muscle.paracervical.spasm.splice(left, 1) : ''

  //       let right = this.muscle.paracervical.spasm.indexOf('right')
  //       right != -1 ? this.muscle.paracervical.spasm.splice(right, 1) : ''
  //     }
  //   })

  //   this.$watch('muscle.trapezius.tenderness', () => {
  //     if (this.muscle.trapezius.tenderness.includes('none')) {
  //       let left = this.muscle.trapezius.tenderness.indexOf('left')

  //       left != -1 ? this.muscle.trapezius.tenderness.splice(left, 1) : ''

  //       let right = this.muscle.trapezius.tenderness.indexOf('right')
  //       right != -1 ? this.muscle.trapezius.tenderness.splice(right, 1) : ''
  //     }
  //   })

  //   this.$watch('muscle.trapezius.spasm', () => {
  //     if (this.muscle.trapezius.spasm.includes('none')) {
  //       let left = this.muscle.trapezius.spasm.indexOf('left')

  //       left != -1 ? this.muscle.trapezius.spasm.splice(left, 1) : ''

  //       let right = this.muscle.trapezius.spasm.indexOf('right')
  //       right != -1 ? this.muscle.trapezius.spasm.splice(right, 1) : ''
  //     }
  //   })
  // },

  methods: {
    isDegree(value) {
      let number = parseInt(value)
      if (!isNaN(number) && number != null) return true
      return false
    },

    radiologyObjNameCheck(name) {
      return this.radiology_selected_findings.some((e) => e.name === name)
    },

    insertDefaults(data) {
      this.parts = []

      for (const [key, value] of Object.entries(data)) {
        this.parts.push({
          field_name: key.replace(/_/g, ' '),
          default: '',
          options: value,
        })
      }
    },

    addInput(section) {
      // Push new input
      if (section == 'nerve') {
        this.radiology.nerve_root_compression.push({
          scan: [],
          segments: {
            c1: 'none',
            c2: 'none',
            c3: 'none',
            c4: 'none',
            c5: 'none',
            c6: 'none',
            c7: 'none',
            c8: 'none',
          },
        })
      }

      if (section == 'spinal_fractures') {
        this.radiology.spinal_fractures.imagings.push({
          segments: [],
          scan: [],
        })
      }
    },

    delInput(objName, aryIndex) {
      return this.radiology[objName].splice(aryIndex, 1)
    },

    async onSave() {
      let PAYLOAD = {}

      PAYLOAD.id = this.apiPostObj.id

      //default validations, in case doctor select half way decided to set it all normal again
      if (this.isMuscleNormal) {
        this.muscle = [
          {
            name: 'paracervical muscles',
            tenderness: 'none',
            spasm: 'none',
          },
          { name: 'trapezius muscles', tenderness: 'none', spasm: 'none' },
        ]
      }

      if (this.isNormalNeurology) {
        this.neuro = {
          sensation: {
            pain_level: 'normal',
            abnormal_pain_additional_info: '',
          },
          muscle_weakness: {
            power_level: 'normal',
            strength_reduced_level: 0,
            power_reduced_info: null,
          },
          reflexes: {
            bicep: {
              right: 'normal',
              left: 'normal',
            },
            brachioradialis: {
              right: 'normal',
              left: 'normal',
            },

            tricep: {
              right: 'normal',
              left: 'normal',
            },
          },
          roos_test: {
            value: 'not applicable',
            comments: '',
          },
        }
      }

      //set non selected radiology to be null

      let filteredFindings = {}

      for (const [key, value] of Object.entries(this.radiology)) {
        if (this.radiology_selected_findings.some((e) => e.value === key)) {
          filteredFindings[`${key}`] = value
        } else filteredFindings[`${key}`] = null
      }

      this.findings.abnormal_radiological_findings = filteredFindings

      //this is what report will need to process output
      let result = {
        radiculopathy: { muscle_wasting: this.muscle_wasting, ...this.neuro },
        findings: this.findings,
        muscles: this.muscle,
        impairment: [],
        diagnosis: this.diagnosis,
        spine_name: 'cervical spine',
      }

      this.parts.forEach((item) => {
        // if combined left and right appears
        result.impairment.push({
          ...item,
          examination_value: item.degrees.find((x) => x.id === item.degree_id)
            .degree,
        })
      })

      PAYLOAD.result = result

      //temporary store in frontend
      this.$store.commit('STORE_SPINE_DATA', result)
      this.$emit('update:readyNext', true)
    },
  },
}
</script>

<style lang="scss" scoped>
.lined-title {
  overflow: hidden;
  text-align: center;

  span {
    position: relative;
    display: inline-block;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      border-bottom: 1px solid;
      width: 591px;
      margin: 0 20px;
    }

    &::before {
      right: 100%;
    }

    &::after {
      left: 100%;
    }
  }
}
</style>
